//import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "./assets/themes/material/material-light/compact/indigo/theme.scss";
//import './assets/themes/nova/nova/theme.scss';
//import './assets/themes/bootstrap4/bootstrap4-light/blue/theme.scss';

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./layout";
import NonLayout from "./nonlayout";

import Authmiddleware from "./routes/middleware";

import { privateRoutes } from "./routes/index";
import { publicRouter } from "./routes/index";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {publicRouter.map((route, index) => {
            const Page = route.element;
            return (
              <Route
                path={route.path}
                key={index}
                element={
                  <NonLayout>
                    <Page />
                  </NonLayout>
                }
              />
            );
          })}
        </Routes>
        <Routes>
          {privateRoutes.map((route, index) => {
            const Page = route.element;
            return (
              <Route
                path={route.path}
                key={index}
                element={
                  <Authmiddleware path={route.path}>
                    <Layout>
                      <Page />
                    </Layout>
                  </Authmiddleware>
                }
              />
            );
          })}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
