import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Languages = () => {
    const [data, setData] = useState([]);
    const [language_key, setLanguageKey] = useState('');
    const [en, setEn] = useState('');
    const [vi, setVi] = useState('');
    const [ja, setJa] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [div1Height, setDiv1Height] = useState(0);
    const [div2Height, setDiv2Height] = useState(0);

    const [deleteButton, setDeleteButton] = useState(false);
    const [editButton, setEditButton] = useState(true);

    const toast = useRef(null);
    const cp = useRef(null);
    const div1 = useRef(null);
    const div2 = useRef(null);


    const fetchData = useCallback(async () => {
        const result = await axios('https://api.languages.itomo.vn/get-all');
        setData(result.data);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const handleResize = useCallback(() => {
        if (div1.current) {
            setDiv1Height(div1.current.offsetHeight);
        }
        if (div2.current) {
            setDiv2Height(div2.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    function removeAccents(str) {
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd').replace(/Đ/g, 'D');
    }

    useEffect(() => {
        if (language_key || en || vi || ja) {
            const filtered = data.filter(item => {
                if (language_key && item.language_key.toLowerCase().includes(language_key.toLowerCase())) {
                    return true;
                }
                if (en && item.en.toLowerCase().includes(en.toLowerCase())) {
                    return true;
                }
                if (vi && removeAccents(item.vi.toLowerCase()).includes(removeAccents(vi.toLowerCase()))) {
                    return true;
                }
                if (ja && item.ja.toLowerCase().includes(ja.toLowerCase())) {
                    return true;
                }
                return false;
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    }, [language_key, en, vi, ja, data]);



    const addLanguage = async () => {
        if (!language_key || !en || !vi || !ja) {
            toast.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Điền thiếu thông tin!' });
            return;
        }

        const isDuplicate = (field, value) => {
            const lowerCaseValue = value.toLowerCase();
            return filteredData.some(data => data[field].toLowerCase() === lowerCaseValue);
        };

        if (isDuplicate('language_key', language_key)) {
            toast.current.show({ severity: 'error', summary: 'Key đã tồn tại', detail: 'Không thể tạo trùng Key!' });
            return;
        }

        if (isDuplicate('en', en) || isDuplicate('vi', vi) || isDuplicate('ja', ja)) {
            confirmDialog({
                message: 'Đã có dữ liệu tương tự. Bạn có muốn tiếp tục?',
                header: 'Xác nhận',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    await axios.post('https://api.languages.itomo.vn/add/', { language_key, en, vi, ja });
                    fetchData();
                    toast.current.show({ severity: 'success', summary: 'Thêm dữ liệu thành công', detail: 'Đã thêm dữ liệu thành công!' });
                },
                reject: () => {
                    // toast.current.show({ severity: 'warn', summary: 'Đã hủy', detail: 'Bạn đã hủy tạo mới', life: 3000 });
                }
            });
        } else {
            await axios.post('https://api.languages.itomo.vn/add/', { language_key, en, vi, ja });
            fetchData();
            toast.current.show({ severity: 'success', summary: 'Thêm dữ liệu thành công', detail: 'Đã thêm dữ liệu thành công!' });
        }
    };



    const onRowEditComplete = async (e) => {
        let _data = [...data];
        let { newData, index } = e;
        _data[index] = newData;
        await axios.put(`https://api.languages.itomo.vn/update-id/${newData.id}`, newData);
        fetchData();
    };

    const deleteLanguage = async (id, event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Xác nhận xóa?',
            icon: 'pi pi-info-circle',
            acceptIcon: 'pi pi-check',
            acceptLabel: 'Xóa',
            rejectLabel: 'Hủy',
            rejectIcon: 'pi pi-times',
            acceptClassName: 'p-button-danger',
            defaultFocus: 'reject',
            accept: async () => {
                await axios.delete(`https://api.languages.itomo.vn/delete-id/${id}`);
                fetchData();
                toast.current.show({ severity: 'success', summary: 'Thông báo', detail: 'Đã xóa thành công!', life: 3000 });
            }
        });
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const onCellSelectToCopy = (event) => {
        if (event.field === 'language_key') {
            navigator.clipboard.writeText(event.value).then(function () {
                toast.current.show({ severity: 'success', summary: 'Đã copy vào clipboard', detail: `Key: ${event.value}`, life: 3000 });
            }, function (err) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: `Could not copy text: ${err}`, life: 3000 });
            });
        }
    };

    const changeButton = () => {
        setDeleteButton(!deleteButton);
        setEditButton(!editButton);
    }

    return (
        <div ref={div1} className='flex flex-column h-full'>
            <div ref={div2} className='w-full flex flex-wrap py-2'>
                <span className="p-float-label w-12 md:w-2 pr-2 mb-2">
                    <InputText id="username" value={language_key} onChange={(e) => setLanguageKey(e.target.value)} className={`w-full ${language_key !== '' ? 'font-bold text-primary' : ''}`} />
                    <label htmlFor="inputtext">Key</label>
                </span>
                <span className="p-float-label w-12 md:w-3 pr-2 mb-2">
                    <InputText id="username" value={en} onChange={(e) => setEn(e.target.value)} className={`w-full ${en !== '' ? 'font-bold text-primary' : ''}`} />
                    <label htmlFor="inputtext">Tiếng Anh</label>
                </span>
                <span className="p-float-label w-12 md:w-3 pr-2 mb-2">
                    <InputText id="username" value={vi} onChange={(e) => setVi(e.target.value)} className={`w-full ${vi !== '' ? 'font-bold text-primary' : ''}`} />
                    <label htmlFor="inputtext">Tiếng Việt</label>
                </span>
                <span className="p-float-label w-12 md:w-3 pr-2 mb-2">
                    <InputText id="username" value={ja} onChange={(e) => setJa(e.target.value)} className={`w-full ${ja !== '' ? 'font-bold text-primary' : ''}`} />
                    <label htmlFor="inputtext">Tiếng Nhật</label>
                </span>
                <div className='flex flex-row w-1  mb-2'>
                    <Toast ref={toast} position="top-center" />
                    <ConfirmDialog />
                    <Button icon="pi pi-filter-slash" severity="danger" outlined onClick={() => { setLanguageKey(''); setEn(''); setVi(''); setJa(''); }} className='w-3rem mr-2' />
                    <Button label="Thêm" onClick={addLanguage} className='w-full' />
                </div>
            </div>

            <div className='shadow-1 bg-white border-round-md p-3 flex-1'>
                <Toast ref={toast} position="top-center" />
                <ConfirmPopup ref={cp} />
                <DataTable value={filteredData} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}
                    scrollable scrollHeight={`${div1Height - div2Height - 28 - 39 - 28}px`} paginator rows={20} rowsPerPageOptions={[10, 20, 30, 50]}
                    cellSelection selectionMode="single" onCellSelect={onCellSelectToCopy} emptyMessage='Không có kết quả'>
                    <Column field="language_key" header="Key" frozen className='w-2 font-bold text-primary border-right-1' ></Column>
                    <Column field="en" header="Tiếng Anh" editor={(options) => textEditor(options)} className='w-3'></Column>
                    <Column field="vi" header="Tiếng Việt" editor={(options) => textEditor(options)} className='w-3'></Column>
                    <Column field="ja" header="Tiếng Nhật" editor={(options) => textEditor(options)} className='w-3'></Column>
                    {editButton && <Column
                        header={<Button icon="pi pi-trash" onClick={changeButton} severity="danger" className='p-1' />}
                        rowEditor={allowEdit} className='py-0'>
                    </Column>}
                    {deleteButton && <Column
                        header={<Button icon="pi pi-pencil" onClick={changeButton} className='p-1' />}
                        body={(rowData) => <Button icon="pi pi-trash" onClick={(event) => deleteLanguage(rowData.id, event)} rounded text severity="danger" />}
                        className='py-0'>
                    </Column>}
                </DataTable>
            </div>
        </div >
    );
};

export default Languages;
