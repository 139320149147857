import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const toast = useRef(null);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().min(5).required("Tối thiểu 5 ký tự"),
      password: Yup.string().min(6).required("Tối thiểu 6 ký tự"),
    }),
    onSubmit: async (values) => {
      try {
        const result = await axios.get(
          `https://api.languages.itomo.vn/users/login/${values.username}/${values.password}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log("result", result.data);
        if (result.data) {
          localStorage.setItem("authLanguages", JSON.stringify(result.data));
          navigate("/");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleSignIn = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex flex-column align-items-center justify-content-center mt-5">
        {/* <img src={`/itomo_logo.png`} alt="Itomo logo" className="mb-5 w-6rem flex-shrink-0" /> */}
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background:
              "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
          }}
        >
          <div
            className="w-full surface-card py-8 px-5 sm:px-8"
            style={{ borderRadius: "53px" }}
          >
            <div className="text-center mb-5">
              <img
                src="/itomo_logo.png"
                alt="itomo logo"
                height="50"
                className="mb-3"
              />
              <div className="text-900 text-3xl font-medium mb-3">
                Welcome !
              </div>
              <span className="text-600 font-medium">Sign in to continue</span>
            </div>

            <div>
              <label
                htmlFor="username"
                className="block text-900 text-xl font-medium mb-2"
              >
                Username
              </label>
              <InputText
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                className="w-full md:w-30rem mb-5"
                style={{ padding: "1rem" }}
                invalid={
                  formik.touched.username && formik.errors.username
                    ? true
                    : false
                }
              />

              <label
                htmlFor="password"
                className="block text-900 font-medium text-xl mb-2"
              >
                Password
              </label>
              <Password
                inputId="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Password"
                toggleMask
                className="w-full mb-5"
                inputClassName="w-full p-3 md:w-30rem"
                invalid={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                pt={{
                  showIcon: "ml-2",
                  input: "pl-5",
                }}
              ></Password>

              <Button
                label="Login"
                type="submit"
                className="w-full p-3 text-xl"
                onClick={handleSignIn}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
