import React, { useRef } from 'react';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';

function Header() {
    const flag = useRef(null);
    const notify = useRef(null);
    const acc = useRef(null);
    const navigate = useNavigate();

    const flagItems = [
        {
            label: 'Việt Nam',
            icon: 'pi pi-image'
        },
        {
            label: 'Nhật Bản',
            icon: 'pi pi-image'
        },
        {
            label: 'Nhật Bản',
            icon: 'pi pi-image'
        }
    ];

    const notifyItems = [
        {
            label: 'Thông báo',
            items: [
                {
                    label: 'Thông báo 1',
                    icon: 'pi pi-bell'
                },
                {
                    label: 'Thông báo 2',
                    icon: 'pi pi-bell'
                }
            ]
        }
    ];

    const accItems = [
        {
            label: 'Thông tin tài khoản',
            icon: 'pi pi-user'
        },
        {
            label: 'Cài đặt',
            icon: 'pi pi-cog'
        },
        {
            separator: true
        },
        {
            label: 'Thoát',
            icon: 'pi pi-power-off',
            command: () => {
                localStorage.removeItem('authLanguages');
                navigate('./login');
            }
        },
    ];

    return (
        <div className='flex flex-nowrap justify-content-between h-4rem'>
            <div className='text-xl font-bold align-content-center pl-5'>
                Danh sách ngôn ngữ
            </div>
            <div className='flex flex-nowrap mr-3 md:mr-5'>
                <div className='align-content-center mr-5'>
                    <Menu model={flagItems} popup ref={flag} id="popup_menu_right" popupAlignment="right" className='mt-4 max-h-15rem overflow-y-auto' />
                    <Avatar image="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/225px-Flag_of_Vietnam.svg.png" onClick={(event) => flag.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup style={{ height: '1.5rem', width: '2rem', borderRadius: '5px' }} />
                </div>
                <div className='align-content-center mr-5'>
                    <Menu model={notifyItems} popup ref={notify} id="popup_menu_right" popupAlignment="right" className='h-25rem w-25rem mt-4 max-h-25rem overflow-y-auto' />
                    <i className="pi pi-bell p-overlay-badge cursor-pointer" style={{ fontSize: '1.5rem' }} onClick={(event) => notify.current.toggle(event)} aria-controls="popup_menu_right">
                        <Badge value="2" severity="danger"></Badge>
                    </i>
                </div>
                <div className='flex flex-nowrap cursor-pointer' onClick={(event) => acc.current.toggle(event)} aria-controls="popup_menu_right">
                    <Menu model={accItems} popup ref={acc} id="popup_menu_right" popupAlignment="right" className='mt-1' />
                    <div className='align-content-center mr-2'>
                        <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
                    </div>
                    <div className='align-content-center hidden md:block'>
                        Amy Elsner
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;