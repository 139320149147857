
import Languages from "../pages/language";
import LoginPage from "../pages/login";


const privateRoutes = [
    { path: '/', element: Languages },
    { path: '/languages', element: Languages }
]

const publicRouter = [{ path: "/login", element: LoginPage }];

export { privateRoutes, publicRouter };
